import {
  BoardViewLoadPage,
  BoardViewDefPage,
} from "./pages";

import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  { path: "/board", element: BoardViewLoadPage },
  { path: "/boardviewdef", element: BoardViewDefPage },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
