import React, { useState, useRef, createContext, useContext } from "react";

function PredictionProvider(props) {
  const [reloadPredictionHistory, setReloadPredictionHistory] = useState(false);
  const [reloadUserBalance, setReloadUserBalance] = useState(false);
  const [renderPriceUpdate, setRenderPriceUpdate] = useState(0);
  const [lastOrderCreated, setLastOrderCreated] = useState(false);
  const objLastPrediction = useRef({
    type: 0,
    price: 0,
  });

  const spinWheelData = useRef({
    spin: false,
    profit: 0,
    data: {},
  });

  const amountActivePredictions = useRef(0);

  const [wheelMenuVisible, setWheelMenuVisible] = useState(false);

  const [assets, setAssets] = useState([]);

  // const [selSymbol, setSelSymbol] = useState("BTC/USDT");
  const [selSymbol, setSelSymbol] = useState({
    symbol: "BTC/USDT",
    precision: 2,
  });

  return (
    <PredictionContext.Provider
      value={{
        reloadPredictionHistory,
        setReloadPredictionHistory,
        reloadUserBalance,
        setReloadUserBalance,
        renderPriceUpdate,
        setRenderPriceUpdate,
        lastOrderCreated,
        setLastOrderCreated,
        objLastPrediction,
        spinWheelData,
        wheelMenuVisible,
        setWheelMenuVisible,
        assets,
        setAssets,
        selSymbol,
        setSelSymbol,
        amountActivePredictions,
      }}
      {...props}
    />
  );
}

const PredictionContext = createContext({});
const usePrediction = () => useContext(PredictionContext);

export { PredictionProvider, usePrediction, PredictionContext };
