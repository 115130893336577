import React, { useState, useEffect } from "react";
import HTTP from "../../../api/HTTP";

import readyTone from "../../../sounds/ready.wav";

import { usePrediction } from "../../../contexts/prediction";

const Countdown = ({ predictionId, minutes, seconds }) => {
  const {
    reloadPredictionHistory,
    setReloadPredictionHistory,
    reloadUserBalance,
    setReloadUserBalance,
  } = usePrediction();

  const [playReadyTone] = useState(new Audio(readyTone));

  const [timeLeft, setTimeLeft] = useState({ minutes, seconds });
  const [isCountdownFinished, setIsCountdownFinished] = useState(false);
  const [isEqual, setIsEqual] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const playTone = () => {
    playReadyTone.currentTime = 0; // Reset audio to start if it's already playing
    playReadyTone.play();
  };

  useEffect(() => {
    // Cleanup function to stop playing audio if component unmounts
    return () => {
      playReadyTone.pause();
      playReadyTone.currentTime = 0;
    };
  }, [playReadyTone]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        const newMinutes =
          prevTime.seconds === 0 ? prevTime.minutes - 1 : prevTime.minutes;
        const newSeconds = prevTime.seconds === 0 ? 59 : prevTime.seconds - 1;
        return { minutes: newMinutes, seconds: newSeconds };
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchPredictionById = async () => {
      try {
        const dataArray = await HTTP.get(
          `/predictions/get-by-id/${predictionId}`
        );
        console.log(dataArray.data);
        setIsCountdownFinished(false);

        if (dataArray.data.amount === dataArray.data.payout) {
          setIsEqual(true);
        } else if (
          dataArray.data.payout > 0 &&
          dataArray.data.amount * 0.05 !== dataArray.data.payout
        ) {
          setIsSuccess(true);
        } else {
          setIsFailure(true);
        }

        // Play ready tone
        // playTone();

        // Reload user balance
        setReloadUserBalance(!reloadUserBalance);
      } catch (err) {
        console.log("fetch-prediction-by-id-error: ", err);
      }
    };

    if (timeLeft.minutes === 0 && timeLeft.seconds === 0) {
      setIsCountdownFinished(true);
      fetchPredictionById();

      setTimeout(() => {
        // console.log("Reload");
        setReloadPredictionHistory(!reloadPredictionHistory);
      }, 2000);
    }
  }, [timeLeft, reloadPredictionHistory]);

  if (isCountdownFinished) {
    return <div>Processing...</div>;
  }

  if (isEqual) {
    return <div className="timerEqual">Money back!</div>;
  }

  if (isSuccess) {
    return <div className="timerSuccess">YOU WON!!!</div>;
  }

  if (isFailure) {
    return <div className="timerLoss">YOU LOST!</div>;
  }

  return (
    <div
      className={
        timeLeft.minutes === 0 && timeLeft.seconds < 10
          ? "timerWarning"
          : "timerDefault"
      }
    >
      {timeLeft.minutes.toString().padStart(2, "0")}:
      {timeLeft.seconds.toString().padStart(2, "0")}
    </div>
  );
};

export default Countdown;
