import React, { useEffect, useState, useRef } from "react";
import Button from "devextreme-react/button";
import { Toast } from "devextreme-react/toast";
import { Popup } from "devextreme-react/popup";
import DataGrid, {
  Column,
  Sorting,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import HTTP from "../../../api/HTTP";
import { io } from "socket.io-client";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleStop } from "@fortawesome/pro-duotone-svg-icons";

import Countdown from "./Countdown";
import Wheel from "../../wheel/Wheel";

import "./Active.css";

import { usePrediction } from "../../../contexts/prediction";

const validateSymbol = (symbol) => {
  // console.log(symbol)
  // DB just contains USD and EUR pairs, not USDT or GEUR
  let validatedSymbol = "";
  if (symbol === "BTC/USD") {
    validatedSymbol = symbol.replace("BTC/USD", "BTC/USDT");
  } else if (symbol === "BTC/EUR") {
    validatedSymbol = symbol.replace("BTC/EUR", "BTC/GEUR");
  } else if (symbol === "ETH/USD") {
    validatedSymbol = symbol.replace("ETH/USD", "ETH/USDT");
  } else if (symbol === "ETH/EUR") {
    validatedSymbol = symbol.replace("ETH/EUR", "ETH/GEUR");
  } else if (symbol === "EUR/USD") {
    validatedSymbol = symbol.replace("EUR/USD", "GEUR/USDT");
  } else if (symbol === "GBP/USD") {
    validatedSymbol = symbol.replace("GBP/USD", "GGBP/USDT");
  } else if (symbol === "EUR/GBP") {
    validatedSymbol = symbol.replace("EUR/GBP", "GEUR/GGBP");
  } else {
    validatedSymbol = symbol;
  }

  return validatedSymbol;
};

const Active = (props) => {
  const { user, cardWidth } = props;

  const {
    reloadPredictionHistory,
    setReloadPredictionHistory,
    reloadUserBalance,
    setReloadUserBalance,
    objLastPrediction,
    spinWheelData,
    wheelMenuVisible,
    setWheelMenuVisible,
    assets,
    amountActivePredictions,
  } = usePrediction();

  const allowedPageSizes = [25, 50];

  const [prices, setPrices] = useState([]);
  const [socket, setSocket] = useState(null);
  const [activePredictions, setActivePredictions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [spinButtonClickable, setSpinButtonClickable] = useState(true);
  const [stopButtonClicked, setStopButtonClicked] = useState(false);
  const [showStartButton, setShowStartButton] = useState(true);
  const [takeProfitButtonClickable, setTakeProfitButtonClickable] =
    useState(false);

  const [toastAlreadyClosed, setToastAlreadyClosed] = useState(false);

  const wheelIsSpinning = useRef(false);

  useEffect(() => {
    const subscribeSymbolsPriceUpdates = async () => {
      if (!isSubscribed) {
        const newSocket = io(process.env.REACT_APP_TIMESCALE_ADAPTER_URL);
        setSocket(newSocket);
        newSocket.emit("join", "price_updates");
        newSocket.on("all_symbols", (symbols) => {
          // console.log(symbols);
          setPrices(symbols);
        });

        setIsSubscribed(true);
        console.log("Subscribed!");
      } else {
        console.log("Already subscribed!");
      }
    };

    const unsubscribeSymbolsPriceUpdates = () => {
      socket.disconnect();
      setSocket(null);
      // console.log("Disconnected 2");
    };

    const fetchActivePredictions = async () => {
      try {
        const dataArray = await HTTP.get(`/predictions/active/${user.userId}`);
        console.log(dataArray.data);
        setActivePredictions(dataArray.data);
        amountActivePredictions.current = dataArray.data.length;

        // Reset socket connection if there are no active predictions
        if (dataArray.data.length < 1) {
          console.log("No active predictions");

          // Reset last prediction
          objLastPrediction.current = {
            type: 0,
            price: 0,
          };

          // Unsubscribe from price updates
          if (socket) {
            unsubscribeSymbolsPriceUpdates();
          }
        } else {
          // Subscribe to price updates
          await subscribeSymbolsPriceUpdates();
        }
      } catch (err) {
        console.log("fetch-active-predictions-error: ", err);
      }
    };

    if (user && user.userId) {
      fetchActivePredictions();
    }
  }, [user, reloadPredictionHistory]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        setIsSubscribed(false);
        console.log("Disconnected");
      }
    };
  }, [socket]);

  useEffect(() => {
    if (!showStartButton) {
      const timer = setTimeout(() => {
        setShowStartButton(true);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showStartButton]);

  const validateSymbolName = (data) => {
    // console.log(data.value)
    let validatedSymbol = validateSymbol(data.value);
    return validatedSymbol;
  };

  const convertDate = (data) => {
    return moment(data.value).format("DD.MM.YYYY HH:mm:ss");
  };

  const setCurrency = (cell) => {
    return cell.data.amount;
  };

  const getPayoutRate = (data) => {
    return (data.value * 100).toFixed(0) + " %";
  };

  const renderPriceFormat = (cell) => {
    // console.log(2000, cell.data.symbol);

    // Get assets to render price precision
    let validatedSymbol = validateSymbol(cell.data.symbol);
    const getAsset = assets.find((el) => el.assetname === validatedSymbol);
    // console.log(10, getAsset.price_precision);
    const pricePrecision = getAsset.price_precision;

    return cell.data.price_entered.toFixed(pricePrecision);
  };

  const percentageDiff = (num1, num2) => {
    const difference = num1 - num2;
    const percentage = (difference / num1) * 100;
    // console.log(percentage);
    return percentage;
  };

  const renderCurrentPrice = (cell) => {
    // console.log(cell.data);

    if (isSubscribed) {
      // If state is pending use price_close as potential payout
      if (cell.data.state === "pending") {
        return <div>-</div>;
      } else {
        for (let i in prices) {
          if (prices[i].symbol === cell.data.symbol) {
            // console.log(prices[i].symbol);
            let className = "equ";
            if (prices[i].price > cell.data.price_entered) {
              className = "inc";
            } else if (prices[i].price < cell.data.price_entered) {
              className = "dec";
            }

            // Get assets to render price precision
            let validatedSymbol = validateSymbol(cell.data.symbol);
            const getAsset = assets.find(
              (el) => el.assetname === validatedSymbol
            );
            // console.log(10, getAsset.price_precision);
            const pricePrecision = getAsset.price_precision;

            return (
              <div className={className}>
                <span className="current-value">
                  {prices[i].price.toFixed(pricePrecision)}
                </span>
                <span className="arrow"> </span>
                <span className="diff">
                  {percentageDiff(
                    prices[i].price,
                    cell.data.price_entered
                  ).toFixed(pricePrecision) + "%"}
                </span>
              </div>
            );
          }
        }
      }
    }
  };

  const validatePredictionType = (cell) => {
    let type = "";
    if (cell.value === 1) {
      type = <span className="orderUp">UP</span>;
    } else if (cell.value === 2) {
      type = <span className="orderDown">DOWN</span>;
    } else {
      type = <span>INVALID TYPE</span>;
    }

    return type;
  };

  const calculatePayout = (cell) => {
    if (isSubscribed) {
      for (let i in prices) {
        if (prices[i].symbol === cell.data.symbol) {
          let currentPrice = 0;
          let payoutClassName;
          let potentialPayout = 0;

          // If state is pending use price_close as potential payout
          if (cell.data.state === "pending") {
            currentPrice = cell.data.price_close;
          } else {
            currentPrice = prices[i].price;
          }

          if (
            cell.data.up_down === 1 &&
            currentPrice > cell.data.price_entered
          ) {
            payoutClassName = "potentialPayoutSuccess";
            potentialPayout =
              cell.data.amount + cell.data.amount * cell.data.payout_rate;
          } else if (
            cell.data.up_down === 1 &&
            currentPrice < cell.data.price_entered
          ) {
            payoutClassName = "potentialPayoutLoss";
            potentialPayout = cell.data.amount * 0.05;
          } else if (
            cell.data.up_down === 2 &&
            currentPrice < cell.data.price_entered
          ) {
            payoutClassName = "potentialPayoutSuccess";
            potentialPayout =
              cell.data.amount + cell.data.amount * cell.data.payout_rate;
          } else if (
            cell.data.up_down === 2 &&
            currentPrice > cell.data.price_entered
          ) {
            payoutClassName = "potentialPayoutLoss";
            potentialPayout = cell.data.amount * 0.05;
          } else if (currentPrice === cell.data.price_entered) {
            payoutClassName = "potentialPayoutEqual";
            potentialPayout = cell.data.amount;
          }

          return (
            <div className={payoutClassName}>
              {cell.data.currency} {potentialPayout.toFixed(2)}
            </div>
          );
        }
      }
    }
  };

  const setCountdown = (cell) => {
    // console.log(cell.data);
    if (cell.data.state === "pending") {
      return (
        <div>
          {cell.data.expiry === "30s" ||
          cell.data.expiry === "1m" ||
          cell.data.expiry === "5m" ||
          cell.data.expiry === "15m" ||
          cell.data.expiry === "30m" ? (
            <Button
              className="takeButton"
              width={70}
              height={25}
              text="Take"
              type="normal"
              // stylingMode="outlined"
              useSubmitBehavior={true}
              onClick={() => takeProfitNow(cell.data)}
            />
          ) : (
            <Button
              className="takeButton"
              width={70}
              height={25}
              text="Take"
              type="normal"
              // stylingMode="outlined"
              useSubmitBehavior={true}
              disabled={true}
            />
          )}
          <Button
            className="riskButton"
            width={70}
            height={25}
            text="Risk"
            type="normal"
            // stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={() => showWheelMenu(cell.data)}
          />
        </div>
      );
    } else {
      const predictionId = cell.data.prediction_id;
      const minutes = parseInt(cell.data.minutes_diff);
      const seconds = parseInt(cell.data.seconds_diff);

      return (
        <Countdown
          predictionId={predictionId}
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
  };

  const validatePredictionClosed = async (predictionId) => {
    try {
      const dataArray = await HTTP.get(
        `/predictions/validate-closed/${predictionId}`
      );
      console.log(dataArray.data);
      return dataArray.data;
    } catch (err) {
      console.log("fetch-closed-predictions-error: ", err);
    }
  };

  const takeProfitNow = async (data) => {
    console.log(4, data);

    // Validate if prediction is already closed
    const isClosed = await validatePredictionClosed(data.prediction_id);
    if (!isClosed.status) {
      spinWheelData.current.profit = data.payout * data.multiplier;
      spinWheelData.current.data = data;
      await takeProfit();
    } else {
      setToastAlreadyClosed(true);
    }
  };

  const showWheelMenu = async (data) => {
    console.log(4, data);

    // Validate if prediction is already closed
    const isClosed = await validatePredictionClosed(data.prediction_id);
    if (!isClosed.status) {
      spinWheelData.current.profit = data.payout * data.multiplier;
      spinWheelData.current.data = data;
      setWheelMenuVisible(true);
    } else {
      setToastAlreadyClosed(true);
    }
  };

  const renderWheelOption = () => {
    // console.log(spinWheelData.current);
    // console.log(1, takeProfitButtonClickable);

    const selExpiry = spinWheelData.current.data.expiry;

    // Render all cases for expiry and spinning wheel to show take profit button
    if (
      selExpiry === "30s" ||
      selExpiry === "1m" ||
      selExpiry === "5m" ||
      selExpiry === "15m" ||
      selExpiry === "30m"
    ) {
      if (wheelIsSpinning.current) {
        setTakeProfitButtonClickable(false);
      } else {
        setTakeProfitButtonClickable(true);
      }
    } else {
      if (spinWheelData.current.data.multiplier > 1) {
        if (wheelIsSpinning.current) {
          setTakeProfitButtonClickable(false);
        } else {
          setTakeProfitButtonClickable(true);
        }
      } else {
        setTakeProfitButtonClickable(false);
      }
    }

    return (
      <>
        <Wheel stopButtonClicked={stopButtonClicked} />

        <div>
          <FontAwesomeIcon
            id="stop-button"
            width={36}
            icon={faCircleStop}
            size="2xl"
            cursor="pointer"
            onClick={stopSpinWheel}
          />
        </div>
        <div style={{ fontSize: 15, marginTop: 20 }}>
          <b>
            Profit: {spinWheelData.current.data.currency}{" "}
            {spinWheelData.current.profit.toFixed(2)}
          </b>
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ flex: 1, marginRight: 10 }}>
            <Button
              className="takeProfit"
              width={160}
              height={50}
              text="Start"
              type="normal"
              // stylingMode="outlined"
              useSubmitBehavior={true}
              disabled={!spinButtonClickable}
              onClick={() => triggerSpinWheel()}
            />
          </div>
          <div style={{ flex: 1 }}>
            {" "}
            <Button
              className="takeProfit"
              width={160}
              height={50}
              text="Take"
              type="normal"
              // stylingMode="outlined"
              useSubmitBehavior={true}
              disabled={!takeProfitButtonClickable}
              onClick={takeProfit}
            />
          </div>{" "}
        </div>
      </>
    );
  };

  const triggerSpinWheel = async () => {
    // console.log(555, spinWheelData.current.data);
    // Validate if prediction is already closed
    const isClosed = await validatePredictionClosed(
      spinWheelData.current.data.prediction_id
    );
    if (!isClosed.status) {
      wheelIsSpinning.current = true;

      spinWheelData.current = {
        spin: true,
        profit: spinWheelData.current.profit,
        data: spinWheelData.current.data,
      };

      setSpinButtonClickable(false);
      setShowStartButton(false);

      setTimeout(() => {
        setSpinButtonClickable(true);
        wheelIsSpinning.current = false;
      }, 8000);
    } else {
      setToastAlreadyClosed(true);
    }
  };

  const stopSpinWheel = async () => {
    setStopButtonClicked(true);

    setTimeout(() => {
      // console.log("Disabled stop button!")
      setStopButtonClicked(false);
    }, 100);
  };

  const takeProfit = async () => {
    // console.log(spinWheelData.current);

    const response = await HTTP.post(
      `/predictions/take-profit/${spinWheelData.current.data.prediction_id}`
    );
    const data = response.data;
    console.log(data);

    if (data.status === "success") {
      console.log("Closed prediction.");

      // Reset values
      setWheelMenuVisible(false);
      spinWheelData.current = {
        spin: false,
        profit: 0,
        data: {},
      };
      setReloadUserBalance(!reloadUserBalance);
      setReloadPredictionHistory(!reloadPredictionHistory);
    } else {
      console.log("Could not close prediction.");
      setToastAlreadyClosed(true);
    }
  };

  return (
    <React.Fragment>
      {activePredictions && isSubscribed && assets && (
        <DataGrid
          style={{
            height: 500,
          }}
          id="gridContainer"
          dataSource={activePredictions}
          showBorders={true}
          remoteOperations={true}
        >
          <Sorting mode="none" />
          <Column
            caption="Timer"
            width={160}
            alignment="center"
            cellRender={(cell) => setCountdown(cell)}
          />
          <Column
            caption="Potential Payout"
            dataType="number"
            width={140}
            alignment="center"
            cellRender={(cell) => calculatePayout(cell)}
          />
          <Column
            caption="Change"
            dataType="number"
            width={130}
            cellRender={renderCurrentPrice}
          />
          <Column
            dataField="symbol"
            dataType="string"
            width={100}
            cellRender={validateSymbolName}
          />
          <Column
            dataField="price_entered"
            caption="Price Entered"
            dataType="number"
            width={100}
            cellRender={(cell) => renderPriceFormat(cell)}
          />
          <Column
            dataField="up_down"
            caption="Type"
            dataType="number"
            width={80}
            cellRender={validatePredictionType}
          />
          <Column
            dataField="amount"
            dataType="number"
            width={90}
            cellRender={setCurrency}
          />
          <Column
            dataField="expiry"
            dataType="string"
            visible={cardWidth < 600 ? false : true}
            width={60}
          />
          <Column
            dataField="payout_rate"
            caption="Rate"
            dataType="number"
            visible={cardWidth < 600 ? false : true}
            width={60}
            cellRender={getPayoutRate}
          />
          <Column
            dataField="time_entered"
            caption="Time Entered"
            dataType="date"
            visible={cardWidth < 600 ? false : true}
            width={160}
            cellRender={convertDate}
          />
          {/* <Column dataField="currency" dataType="string" /> */}
          <Paging defaultPageSize={allowedPageSizes[0]} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      )}
      <Toast
        visible={toastAlreadyClosed}
        message="Prediction already closed! Please refresh your browser."
        type="error"
        displayTime={3000}
        closeOnClick={true}
        onHiding={() => {
          setToastAlreadyClosed(false);
        }}
      />
      <Popup
        width={360}
        height={500}
        showTitle={true}
        title={"Double Or Nothing"}
        dragEnabled={false}
        showCloseButton={false}
        hideOnOutsideClick={false}
        visible={wheelMenuVisible}
        onHiding={() => setWheelMenuVisible(false)}
        contentRender={renderWheelOption}
      />
    </React.Fragment>
  );
};

export default Active;
