import React, { useEffect, useState, useRef } from "react";
import { Popup } from "devextreme-react/popup";

import HTTP from "../../api/HTTP";

import "./Wheel.css";

import { usePrediction } from "../../contexts/prediction";

const Wheel = (props) => {
  const { stopButtonClicked } = props;

  const {
    reloadPredictionHistory,
    setReloadPredictionHistory,
    reloadUserBalance,
    setReloadUserBalance,
    spinWheelData,
    setWheelMenuVisible,
  } = usePrediction();

  const items = [
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
    "WIN",
    "LOSE",
  ];

  const selectedItem = useRef(null);
  const spinResultText = useRef({ header: "", body: "" });

  const profit = useRef(0);
  const spinResult = useRef("");
  const wheelSpinning = useRef("");

  const [resultPopup, setResultPopup] = useState(false);

  useEffect(() => {
    const selectItem = async () => {
      spinResult.current = await getSpinWheelResult();
      selectedItem.current = spinResult.current.status;
      wheelSpinning.current = "spinning";

      // Check if result status is even
      const isEven = spinResult.current.status % 2 === 0;

      if (isEven) {
        profit.current =
          spinWheelData.current.data.payout * spinResult.current.multiplier;
        spinResultText.current.header = "Congratulations!";
        spinResultText.current.body =
          "You won! Play again to double your profit!";
      } else {
        profit.current = 0;
        spinResultText.current.header = "Maybe next time...";
        spinResultText.current.body = "You lost!";
      }

      // Reset Wheel
      spinWheelData.current.spin = false;
      setTimeout(() => {
        if (wheelSpinning.current !== "stopped") {
          selectedItem.current = null;
          wheelSpinning.current = "";

          // Show result page and update data
          spinWheelData.current.profit = profit.current;
          spinWheelData.current.data.multiplier = spinResult.current.multiplier;
          setResultPopup(true);
          setTimeout(() => {
            setResultPopup(false);

            // Check if result status is even
            const isEven = spinResult.current.status % 2 === 0;
            // console.log(444, isEven);

            // If lost reset values and close wheel menu
            if (!isEven) {
              spinWheelData.current = {
                spin: false,
                profit: 0,
                data: {},
              };
              setReloadUserBalance(!reloadUserBalance);
              setReloadPredictionHistory(!reloadPredictionHistory);
              setWheelMenuVisible(false);
            }
          }, 2000);
        }
      }, 5000);
    };

    const getSpinWheelResult = async () => {
      const response = await HTTP.get(
        `/predictions/spin-wheel-result/${spinWheelData.current.data.prediction_id}`
      );
      const data = response.data;
      // console.log(data);
      return data;
    };

    // console.log(wheelSpinning.current);

    if (spinWheelData.current.spin && wheelSpinning.current === "") {
      selectItem();
    }
  }, [spinWheelData.current, wheelSpinning.current]);

  useEffect(() => {
    if (stopButtonClicked && wheelSpinning.current === "spinning") {
      console.log(2, "Stop Button Clicked");
      wheelSpinning.current = "stopped";

      setTimeout(() => {
        // Check if result status is even
        const isEven = spinResult.current.status % 2 === 0;

        if (isEven) {
          profit.current =
            spinWheelData.current.data.payout * spinResult.current.multiplier;
          spinResultText.current.header = "Congratulations!";
          spinResultText.current.body =
            "You won! Play again to double your profit!";
        } else {
          profit.current = 0;
          spinResultText.current.header = "Maybe next time...";
          spinResultText.current.body = "You lost!";
        }

        // Reset Wheel
        spinWheelData.current.spin = false;
        if (wheelSpinning.current === "stopped") {
          // Show result page and update data
          spinWheelData.current.profit = profit.current;
          spinWheelData.current.data.multiplier = spinResult.current.multiplier;
          setResultPopup(true);
          setTimeout(() => {
            setResultPopup(false);

            // Reset
            selectedItem.current = null;
            wheelSpinning.current = "";

            // Check if result status is even
            const isEven = spinResult.current.status % 2 === 0;
            // console.log(444, isEven);

            // If lost reset values and close wheel menu
            if (!isEven) {
              spinWheelData.current = {
                spin: false,
                profit: 0,
                data: {},
              };
              setReloadUserBalance(!reloadUserBalance);
              setReloadPredictionHistory(!reloadPredictionHistory);
              setWheelMenuVisible(false);
            }
          }, 3000);
        }
      }, 2000);
    } else {
      console.log("Wheel not spinning!");
    }
  }, [stopButtonClicked, wheelSpinning.current]);

  const wheelVars = {
    "--nb-item": items.length,
    "--selected-item": selectedItem.current,
  };

  // const spinning = selectedItem.current !== null ? "spinning" : "";

  const renderResult = () => {
    return <div style={{ fontSize: 15 }}>{spinResultText.current.body}</div>;
  };

  return (
    <>
      <div className="wheel-container">
        <div className={`wheel ${wheelSpinning.current}`} style={wheelVars}>
          {items.map((item, index) => (
            <div
              className={`wheel-item ${index % 2 === 0 ? "win" : "lose"}`} // Optional: Add index with -${index} before last tick
              key={index}
              style={{ "--item-nb": index }}
            >
              {item}
              {/* {item}-{index} Just for testing purpose */}
            </div>
          ))}
        </div>
      </div>
      <Popup
        width={350}
        height={100}
        showTitle={true}
        title={spinResultText.current.header}
        dragEnabled={false}
        showCloseButton={false}
        hideOnOutsideClick={false}
        visible={resultPopup}
        onHiding={() => setResultPopup(false)}
        contentRender={renderResult}
      />
    </>
  );
};

export default Wheel;
