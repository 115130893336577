import React, { useState, useEffect } from 'react';
import './Board.css';
import HTTP from '../../api/HTTP';
import { useAuth } from '../../contexts/auth';
import BoardViewDefCard from './BoardViewDefCard';

const BoardViewDefPage = () => {
  const { user } = useAuth();

  const [cardRows, setCardRows] = useState([]);
  const [cardLayout, setCardLayout] = useState([]);
  const [cardPageReload, setCardPageReload] = useState(false);

  useEffect(() => {
    const fetchRows = async () => {
      try {
        const dataArray = await HTTP.get(`/board/board-card-rows/${user.userId}`);
        setCardRows(dataArray.data);
        // console.log(dataArray.data);
      } catch (err) {
        console.log('fetchCardLayout Error: ', err);
      }
    };

    const fetchCardLayout = async () => {
      try {
        const dataArray = await HTTP.get(`/board/board-card/${user.userId}`);
        setCardLayout(dataArray.data);
        console.log(dataArray.data);
      } catch (err) {
        console.log('fetchCardLayout Error: ', err);
      }
    };

    if (user  && user.userId) {
      fetchRows();
      fetchCardLayout();
    }
  }, [user, user.userId, cardPageReload]);

  const rowCards = (anRow) => {
    const result = cardLayout.filter((obj) => {
      return obj.board_rowid === anRow;
    });
    return result;
  };

  const doCardPageReload = (anDoReload) => {
    if (anDoReload) {
      setCardPageReload(!cardPageReload);
    }
  };

  return (
    <React.Fragment>
      <div className='line-dialog-aera' id='board-layout-line2'>
        <div id='board-layout-label'>My Board Definition</div>
      </div>
      <div>
        {cardRows.map((card_item, index) => {
          const rowCardCount = cardRows.length - 1;
          const oneLine = rowCards(card_item.board_rowid);
          const colCardCount = oneLine.length - 1;
          return (
            <div className={'flex-container-board'} key={index}>
              {oneLine.map((colItem, index) => {
                return (
                  <div className={'content-block'} key={index} style={{ flexBasis: 300, flexGrow: colItem.cardstyle }}>
                    <div className={'dx-card'}>
                      <div
                        style={{ height: colItem.cardheight + 'vh', marginRight: 20, marginLeft: 20, marginTop: 10 }}
                      >
                        <div>
                          <BoardViewDefCard
                            user={user}
                            selCard={colItem}
                            rowCardCount={rowCardCount}
                            colCardCount={colCardCount}
                            doCardPageReload={doCardPageReload}
                            defType={2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default BoardViewDefPage;
