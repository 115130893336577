import HTTP from "../api/HTTP";

class AuthService {
  login = (email, password, portalType) => {
    return HTTP.post(
      "/auth/login",
      {
        email: email,
        password: password,
        portalType: portalType,
      },
      {
        headers: {
          Authorization: "Bearer " + process.env.REACT_APP_APIKEY,
          "Client-type": "service-provider-portal",
        },
      }
    );
  };

  getAuthUser = () => {
    return HTTP.get("/auth/me").then((response) => response.data);
  };

  resetPasswordRequest = (email) => {
    return HTTP.post("/auth/reset-password/email", {
      email: email,
    });
  };

  setNewPassword = (token, password, confirmPassword) => {
    return HTTP.post("/auth/reset-password/change", {
      token: token,
      newPassword: password,
      newPasswordRetry: password,
    });
  };
}

export default new AuthService();
