import React, { useCallback, useEffect, useState, useRef } from "react";
import Tabs from "devextreme-react/tabs";
import Button from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";
import { Toast } from "devextreme-react/toast";
import SelectBox from "devextreme-react/select-box";
import { NumberBox } from "devextreme-react/number-box";
import { ProgressBar } from "devextreme-react/progress-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/pro-duotone-svg-icons";

import LightweightChart from "../../components/lightweight-chart/chart";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

import "./BoardCards.css";
import "./PredictionsCard.css";

import HTTP from "../../api/HTTP";

import { usePrediction } from "../../contexts/prediction";

const PredictionsCard = (props) => {
  const { user, card_height, cardWidth, cardHeight } = props;

  const {
    reloadPredictionHistory,
    setReloadPredictionHistory,
    reloadUserBalance,
    setReloadUserBalance,
    renderPriceUpdate,
    lastOrderCreated,
    setLastOrderCreated,
    objLastPrediction,
    assets,
    setAssets,
    selSymbol,
    setSelSymbol,
    amountActivePredictions,
  } = usePrediction();

  const payoutRate = 0.7;
  const expiry = ["5s", "15s", "30s", "1m", "5m", "15m", "30m"]; // Add "Custom" to enable long term predictions
  const chartResolution = ["1s", "1m", "5m", "15m", "30m", "1h", "4h", "1d"];
  const chartPeriod = ["1D", "5D", "1M", "3M", "6M", "1Y"];

  const orderTimer = useRef();
  const currentPrice = useRef(0);
  const currency = useRef("USDT");
  const orderCanceled = useRef(false);
  const isPeriodChanged = useRef(false);
  const selStepRange = useRef(10);
  const maxNumberbox = useRef(100000);
  const accountBalance = useRef(0);

  const [selAmount, setSelAmount] = useState(10);
  const [chartWidthHeight, setChartWidthHeight] = useState({
    width: 700,
    height: 350,
  });
  const [progressBarWidth, setProgressBarWidth] = useState(640);
  const [selExpiry, setSelExpiry] = useState(expiry[2]);
  const [selChartType, setSelChartType] = useState("Area");
  const [selResolution, setSelResolution] = useState(chartResolution[0]);
  const [selPeriod, setSelPeriod] = useState(-1);
  const [amountPayout, setAmountPayout] = useState(17);
  const [percentagePredictionsUp, setPercentagePredictionsUp] = useState(0);
  const [percentagePredictionsDown, setPercentagePredictionsDown] = useState(0);

  const [toastHint, setToastHint] = useState(false);
  const [cancelOrderTimer, setCancelOrderTimer] = useState(3);
  const [cancelMenuVisible, setCancelMenuVisible] = useState(false);

  const [toastActivePredictions, setToastActivePredictions] = useState(false);

  useEffect(() => {
    // console.log(1234, cardWidth, cardHeight);

    if (cardWidth > 100) {
      // Define dynamic chart resolution
      if (cardWidth > 800 && cardWidth < 1080) {
        setChartWidthHeight({
          width: cardWidth - 380,
          height: 330,
        });
        setProgressBarWidth(cardWidth - 380);
      } else if (cardWidth > 500 && cardWidth < 800) {
        setChartWidthHeight({
          width: 300,
          height: 330,
        });
        setProgressBarWidth(290);
      } else if (cardWidth < 500) {
        setChartWidthHeight({
          width: 300,
          height: 150,
        });
        setProgressBarWidth(290);
      } else if (cardWidth > 1080) {
        setChartWidthHeight({
          width: 700,
          height: 330,
        });
        setProgressBarWidth(640);
      }
    }
  }, [cardWidth, cardHeight]);

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const dataArray = await HTTP.get(`/chart/get-assets`);
        console.log(dataArray.data);
        setAssets(dataArray.data);
      } catch (err) {
        console.log("fetch-assets-error: ", err);
      }
    };

    if (user && user.userId) {
      fetchAssets();
    }
  }, [user]);

  // useEffect(() => {
  //   const setPricePrec = async () => {
  //     const getAsset = assets.find((el) => el.assetname === selSymbol);
  //     // console.log(10, getAsset.price_precision);
  //     setPricePrecision(getAsset.price_precision);
  //   };

  //   if (user && user.userId && assets.length > 0) {
  //     setPricePrec();
  //   }
  // }, [user, selSymbol, assets]);

  useEffect(() => {
    const fetchPercentagePredictions = async () => {
      // console.log(selSymbol.symbol);

      // DB just contains USD and EUR pairs, not USDT or GEUR
      let validatedSymbol = "";
      if (selSymbol.symbol === "BTC/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("BTC/USDT", "BTC/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "BTC/GEUR") {
        validatedSymbol = selSymbol.symbol
          .replace("BTC/GEUR", "BTC/EUR")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "ETH/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("ETH/USDT", "ETH/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "ETH/GEUR") {
        validatedSymbol = selSymbol.symbol
          .replace("ETH/GEUR", "ETH/EUR")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "GEUR/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("GEUR/USDT", "EUR/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "GGBP/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("GGBP/USDT", "GBP/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "GEUR/GGBP") {
        validatedSymbol = selSymbol.symbol
          .replace("GEUR/GGBP", "EUR/GBP")
          .replace("/", "%2F");
      } else {
        validatedSymbol = selSymbol.symbol.replace("/", "%2F");
      }

      try {
        const dataArray = await HTTP.get(
          `/predictions/percentage/${validatedSymbol}`
        );
        // console.log(dataArray.data);

        // Check if there are predictions existing in database
        if (dataArray.data.length === 0) {
          setPercentagePredictionsUp(0);
          setPercentagePredictionsDown(0);
        } else if (dataArray.data.length === 1) {
          if (dataArray.data[0].up_down === 1) {
            setPercentagePredictionsDown(0);
            setPercentagePredictionsUp(
              parseInt(Math.round(parseFloat(dataArray.data[0].percentage)))
            );
          } else if (dataArray.data[0].up_down === 2) {
            setPercentagePredictionsUp(0);
            setPercentagePredictionsDown(
              parseInt(Math.round(parseFloat(dataArray.data[0].percentage)))
            );
          }
        } else if (dataArray.data.length === 2) {
          setPercentagePredictionsUp(
            parseInt(Math.round(parseFloat(dataArray.data[0].percentage)))
          );

          setPercentagePredictionsDown(
            parseInt(Math.round(parseFloat(dataArray.data[1].percentage)))
          );
        }
      } catch (err) {
        console.log("fetch-percentage-predictions-error: ", err);
      }
    };

    if (user && user.userId) {
      const intervalId = setInterval(() => {
        fetchPercentagePredictions();
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [user, selSymbol]);

  useEffect(() => {
    const fetchUserAccount = async () => {
      try {
        const dataArray = await HTTP.get(
          `/user/account/${user.userId}/${currency.current}`
        );
        // console.log(dataArray.data);
        accountBalance.current = dataArray.data;
      } catch (err) {
        console.log("fetch-user-account-error: ", err);
      }
    };

    if (user && user.userId) {
      fetchUserAccount();
    }
  }, [user, reloadUserBalance]);

  useEffect(() => {
    if (cancelMenuVisible) {
      const interval = setInterval(() => {
        setCancelOrderTimer(cancelOrderTimer - 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [cancelMenuVisible, cancelOrderTimer]);

  useEffect(() => {
    if (cancelMenuVisible) {
      const interval = setInterval(() => {
        setCancelOrderTimer(cancelOrderTimer - 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [cancelMenuVisible, cancelOrderTimer]);

  useEffect(() => {
    const fetchLastPrediction = async () => {
      let validatedSymbol = "";
      if (selSymbol.symbol === "BTC/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("BTC/USDT", "BTC/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "BTC/GEUR") {
        validatedSymbol = selSymbol.symbol
          .replace("BTC/GEUR", "BTC/EUR")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "ETH/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("ETH/USDT", "ETH/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "ETH/GEUR") {
        validatedSymbol = selSymbol.symbol
          .replace("ETH/GEUR", "ETH/EUR")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "GEUR/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("GEUR/USDT", "EUR/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "GGBP/USDT") {
        validatedSymbol = selSymbol.symbol
          .replace("GGBP/USDT", "GBP/USD")
          .replace("/", "%2F");
      } else if (selSymbol.symbol === "GEUR/GGBP") {
        validatedSymbol = selSymbol.symbol
          .replace("GEUR/GGBP", "EUR/GBP")
          .replace("/", "%2F");
      } else {
        validatedSymbol = selSymbol.symbol.replace("/", "%2F");
      }

      console.log(selSymbol.symbol);

      try {
        const dataArray = await HTTP.get(
          `/predictions/get-last/${user.userId}/${validatedSymbol}`
        );
        // console.log(dataArray.data);

        // Set last prediction data
        if (dataArray.data.length > 0) {
          const data = dataArray.data[0];
          objLastPrediction.current = {
            type: data.up_down,
            price: data.price_entered,
          };
        } else {
          // Set last prediction data
          objLastPrediction.current = {
            type: 0,
            price: 0,
          };
        }
      } catch (err) {
        console.log("fetch-last-prediction-error: ", err);
      }
    };

    if (user && user.userId) {
      fetchLastPrediction();
    }
  }, [user, selSymbol, lastOrderCreated]);

  const onValueAssetChanged = async (e) => {
    // console.log(e);
    const getAsset = await assets.find((el) => el.assetname === e.value);
    // console.log(10, getAsset.price_precision);
    setSelSymbol({ symbol: e.value, precision: getAsset.price_precision });
    // console.log(selSymbol)
  };

  const onValueSelectExpiryChanged = (e) => {
    // console.log(e);
    setSelExpiry(e.value);
    // console.log(selExpiry)

    if (e.value === "5s" || e.value === "15s") {
      maxNumberbox.current = 10;
    } else {
      maxNumberbox.current = 100000;
    }
  };

  const handleValueAmountChange = useCallback(
    (e) => {
      // console.log(e);

      const newValue = e.value;
      const prevValue = e.previousValue;

      if (prevValue > newValue) {
        selStepRange.current = 5;
      } else if (prevValue < newValue && newValue < 50) {
        selStepRange.current = 10;
      } else if (prevValue < newValue && newValue >= 50) {
        selStepRange.current = 25;
      }

      setAmountPayout(newValue + newValue * payoutRate);

      setSelAmount(newValue);
      // console.log(selAmount);
    },
    [payoutRate]
  );

  // const onValueSelectChartTypeChanged = (e) => {
  //   // console.log(e);
  //   setSelChartType(e.value);
  //   // console.log(selChartType)
  // };

  const onValueSelectResolutionChanged = (e) => {
    // console.log(e);

    // Check if period was set instead of resolution
    if (!isPeriodChanged.current) {
      // console.log("Resolution changed");
      setSelPeriod(-1);
      setSelResolution(e.value);
      // console.log(selResolution)
    } else {
      // console.log("Period changed");
      isPeriodChanged.current = false;
    }
  };

  const onValueSelectPeriodChanged = (e) => {
    isPeriodChanged.current = true;
    // console.log(e);
    setSelPeriod(e.addedItems[0]);
    // console.log(selPeriod);

    let resolution = "";
    if (e.addedItems[0] === "1D") {
      resolution = "1m";
    } else if (e.addedItems[0] === "5D") {
      resolution = "5m";
    } else if (e.addedItems[0] === "1M") {
      resolution = "30m";
    } else if (e.addedItems[0] === "3M") {
      resolution = "1h";
    } else if (e.addedItems[0] === "6M") {
      resolution = "4h";
    } else if (e.addedItems[0] === "1Y") {
      resolution = "1d";
    }
    // console.log(resolution)
    setSelResolution(resolution);
    console.log(selResolution);
  };

  const statusFormat = () => {
    return ``;
  };

  const renderCancelOption = () => {
    return (
      <>
        <div className="placeOrderValues">
          <div style={{ marginBottom: 15 }}>
            <div
              style={{
                fontSize: 16,
              }}
            >
              Price: {currentPrice.current.toFixed(selSymbol.precision)}{" "}
              {currency.current}
            </div>
            <div
              style={{
                fontSize: 12,
              }}
            >
              Reserved for <b>{cancelOrderTimer}</b> seconds.
            </div>
          </div>
        </div>
        <Button
          className="placePrediction"
          width={225}
          height={40}
          text="Cancel"
          type="normal"
          stylingMode="outlined"
          useSubmitBehavior={true}
          onClick={cancelOrder}
        />
      </>
    );
  };

  const cancelOrder = async () => {
    console.log("Canceled order.");
    setCancelMenuVisible(false);
    orderCanceled.current = true;
    clearTimeout(orderTimer.current);
  };

  const placeOrderOptions = async (orderType) => {
    // console.log(orderType);

    // Check account balance
    if (accountBalance.current > selAmount) {
      // Limit active predictions to 25
      if (amountActivePredictions.current < 25) {
        // All expiries above 30 seconds will get a price reservation for 3 seconds
        if (
          selExpiry === "30s" ||
          selExpiry === "1m" ||
          selExpiry === "5m" ||
          selExpiry === "15m" ||
          selExpiry === "30m"
        ) {
          // Reserve price and set 3 seconds timer to cancel
          setCancelOrderTimer(3);
          setCancelMenuVisible(true);
          orderCanceled.current = false;
          currentPrice.current = renderPriceUpdate;
          orderTimer.current = setTimeout(async () => {
            // console.log(orderTimer.current)
            // console.log(orderCanceled.current);
            setCancelMenuVisible(false);
            if (!orderCanceled.current) {
              console.log("Placing order...");
              await placeOrder(orderType, currentPrice.current);
            } else {
              console.log("Order not placed...");
              orderCanceled.current = false;
            }
          }, 3000);
        } else {
          currentPrice.current = renderPriceUpdate;
          await placeOrder(orderType, currentPrice.current);
        }
      } else {
        setToastActivePredictions(true);
        console.log("Active predictions are limited!");
      }
    } else {
      setToastHint(true);
      console.log("Not enough funds!");
    }
  };

  const placeOrder = async (orderType, currentPrice) => {
    // console.log(orderType);
    // console.log(currentPrice);
    let validatedSymbol = "";
    if (selSymbol.symbol === "BTC/USDT") {
      validatedSymbol = selSymbol.symbol
        .replace("BTC/USDT", "BTC/USD")
        .replace("/", "%2F");
    } else if (selSymbol.symbol === "BTC/GEUR") {
      validatedSymbol = selSymbol.symbol
        .replace("BTC/GEUR", "BTC/EUR")
        .replace("/", "%2F");
    } else if (selSymbol.symbol === "ETH/USDT") {
      validatedSymbol = selSymbol.symbol
        .replace("ETH/USDT", "ETH/USD")
        .replace("/", "%2F");
    } else if (selSymbol.symbol === "ETH/GEUR") {
      validatedSymbol = selSymbol.symbol
        .replace("ETH/GEUR", "ETH/EUR")
        .replace("/", "%2F");
    } else if (selSymbol.symbol === "GEUR/USDT") {
      validatedSymbol = selSymbol.symbol
        .replace("GEUR/USDT", "EUR/USD")
        .replace("/", "%2F");
    } else if (selSymbol.symbol === "GGBP/USDT") {
      validatedSymbol = selSymbol.symbol
        .replace("GGBP/USDT", "GBP/USD")
        .replace("/", "%2F");
    } else if (selSymbol.symbol === "GEUR/GGBP") {
      validatedSymbol = selSymbol.symbol
        .replace("GEUR/GGBP", "EUR/GBP")
        .replace("/", "%2F");
    } else {
      validatedSymbol = selSymbol.symbol.replace("/", "%2F");
    }

    const response = await HTTP.post(
      `/predictions/create-prediction/${user.userId}/${validatedSymbol}/${selExpiry}/${currency.current}/${selAmount}/${currentPrice}/${payoutRate}/${orderType}`
    );
    const data = response.data;
    console.log(data);

    if (data.status === "success") {
      // console.log("Placed order.");

      setLastOrderCreated(!lastOrderCreated);
      setReloadUserBalance(!reloadUserBalance);
      setReloadPredictionHistory(!reloadPredictionHistory);

      // Set to 1s resolution if expiry is under 1m
      if (selExpiry === "5s" || selExpiry === "15s" || selExpiry === "30s") {
        setSelResolution(chartResolution[0]);
      }
    } else {
      console.log("Could not place order.");
    }
  };

  const createPredictionsUser = async () => {
    const data = {
      email: "testinger@test.com",
      firstName: "Test",
      lastName: "Test 2",
      password: "1234",
      phone: "000",
    };

    try {
      const dataArray = await HTTP.post(`/client/create-user`, data);
      console.log(dataArray.data);
    } catch (err) {
      console.log("create-predictions-user-error: ", err);
    }
  };

  const createUserAccount = async () => {
    const data = {
      userId: 19,
      accountIdentifier: "ident",
      currency: "USDT",
      balance: 0,
      status: "generated",
    };

    try {
      const dataArray = await HTTP.post(`/client/create-user-account`, data);
      console.log(dataArray.data);
    } catch (err) {
      console.log("create-user-account-error: ", err);
    }
  };

  const getUsers = async () => {
    try {
      const dataArray = await HTTP.get(`/client/users`);
      console.log(dataArray.data);
    } catch (err) {
      console.log("get-users-error: ", err);
    }
  };

  const getUserById = async () => {
    const userId = 17;

    try {
      const dataArray = await HTTP.get(`/client/user-by-id/${userId}`);
      console.log(dataArray.data);
    } catch (err) {
      console.log("get-user-by-id-error: ", err);
    }
  };

  const getUserStatementsById = async () => {
    const userId = 17;

    try {
      const dataArray = await HTTP.get(`/client/user-statements/${userId}`);
      console.log(dataArray.data);
    } catch (err) {
      console.log("get-user-statements-by-id-error: ", err);
    }
  };

  const getUserAccount = async () => {
    const userId = 28;

    try {
      const dataArray = await HTTP.get(`/client/user-account/${userId}`);
      console.log(dataArray.data);
    } catch (err) {
      console.log("get-user-account-error: ", err);
    }
  };

  const userDeposit = async () => {
    const data = {
      userId: 28,
      amount: 50,
      currency: "USDT",
    };

    try {
      const dataArray = await HTTP.post(`/client/user-deposit`, data);
      console.log(dataArray.data);
    } catch (err) {
      console.log("user-deposit-error: ", err);
    }
  };

  const userWithdraw = async () => {
    const data = {
      userId: 28,
      amount: 50,
      currency: "USDT",
    };

    try {
      const dataArray = await HTTP.post(`/client/user-withdraw`, data);
      console.log(dataArray.data);
    } catch (err) {
      console.log("user-withdraw-error: ", err);
    }
  };

  return (
    <React.Fragment>
      <div className="sd-header item">
        <div className="flex-container">
          <div id="header-balance" className="dx-fieldset-header">
            Balance:{" "}
            <b>
              {currency.current} {accountBalance.current.toFixed(2)}
            </b>
          </div>
          {/* <Button
            width={225}
            height={40}
            text="Create User"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={createPredictionsUser}
          />
          <Button
            width={225}
            height={40}
            text="Create User Account"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={createUserAccount}
          />
          <Button
            width={225}
            height={40}
            text="Get Users"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={getUsers}
          />
          <Button
            width={225}
            height={40}
            text="Get User By Id"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={getUserById}
          />
          <Button
            width={225}
            height={40}
            text="Get User Statements"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={getUserStatementsById}
          />
          <Button
            width={225}
            height={40}
            text="Get User Account"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={getUserAccount}
          />
          <Button
            width={225}
            height={40}
            text="Deposit"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={userDeposit}
          />
          <Button
            width={225}
            height={40}
            text="Withdraw"
            type="normal"
            stylingMode="outlined"
            useSubmitBehavior={true}
            onClick={userWithdraw}
          /> */}
        </div>
        {assets && (
          <div
            style={{
              height: `${card_height}vh - 120px`,
              width: cardWidth - 10,
              marginBottom: 10,
            }}
          >
            <ResponsiveBox>
              <Row ratio={8} />
              <Row ratio={1} />
              <Row ratio={0.1} screen="sm xs" />
              <Col ratio={4} />
              <Col ratio={1} screen="md lg" />
              <Col ratio={1} screen="md lg" />
              <Item>
                <Location screen="md lg" row={0} col={0} />
                <div className="prediction-header-items">
                  <div id="pred-asset-area" style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <span>Asset</span>
                      <SelectBox
                        id="pred-asset-select"
                        items={assets}
                        displayExpr="assetname"
                        valueExpr="assetname"
                        defaultValue={selSymbol.symbol}
                        onValueChanged={onValueAssetChanged}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <span>Resolution</span>
                      <SelectBox
                        id="pred-resolution-select"
                        className="selectBoxChart"
                        items={chartResolution}
                        defaultValue={chartResolution[1]}
                        value={selResolution}
                        onValueChanged={onValueSelectResolutionChanged}
                      />
                    </div>{" "}
                    <div style={{ flex: 1 }}>
                      <Tabs
                        id="tabsPeriod"
                        dataSource={chartPeriod}
                        selectedIndex={chartPeriod.indexOf(selPeriod)}
                        scrollingEnabled={false}
                        onSelectionChanged={onValueSelectPeriodChanged}
                      />
                    </div>
                  </div>
                </div>
                <div className="prediction-header-items">
                  <LightweightChart
                    userid={user.userId}
                    chartType={selChartType}
                    chartResolution={selResolution}
                    chartPeriod={selPeriod}
                    chartWidthHeight={chartWidthHeight}
                  />
                </div>
              </Item>
              <Item>
                <Location screen="sm xs" row={0} col={0} />
                <div className="prediction-header-items">
                  <div id="pred-asset-area-row" style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <span>Asset</span>
                      <SelectBox
                        id="pred-asset-select-row"
                        items={assets}
                        displayExpr="assetname"
                        valueExpr="assetname"
                        defaultValue={selSymbol.symbol}
                        onValueChanged={onValueAssetChanged}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <span>Resolution</span>
                      <SelectBox
                        id="pred-resolution-select-row"
                        className="selectBoxChart"
                        items={chartResolution}
                        defaultValue={chartResolution[1]}
                        value={selResolution}
                        onValueChanged={onValueSelectResolutionChanged}
                      />
                    </div>
                  </div>
                </div>
                <div className="prediction-header-items">
                  <Tabs
                    id="tabsPeriod-row"
                    dataSource={chartPeriod}
                    selectedIndex={chartPeriod.indexOf(selPeriod)}
                    scrollingEnabled={false}
                    onSelectionChanged={onValueSelectPeriodChanged}
                  />
                  <LightweightChart
                    userid={user.userId}
                    chartType={selChartType}
                    chartResolution={selResolution}
                    chartPeriod={selPeriod}
                    chartWidthHeight={chartWidthHeight}
                  />
                </div>
              </Item>
              <Item>
                <Location screen="md lg" row={0} col={1} rowspan={2} />
                <div>
                  <span>Expiry</span>
                  <SelectBox
                    id="pred-expiry-select"
                    items={expiry}
                    defaultValue={selExpiry}
                    value={selExpiry}
                    onValueChanged={onValueSelectExpiryChanged}
                  />
                </div>
                <div>
                  <div>
                    <Button
                      id="placeOrderUp"
                      className="orderIcons"
                      text="UP"
                      onClick={() => placeOrderOptions(1)}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </Button>
                  </div>
                  <div id="orderPrice">
                    {objLastPrediction.current.price > 0 && (
                      <span id={`lastPlacedOrderPrice`}>
                        <b>
                          {objLastPrediction.current.price.toFixed(
                            selSymbol.precision
                          )}
                        </b>
                      </span>
                    )}
                    <span id="placeOrderPrice">
                      <b>{renderPriceUpdate.toFixed(selSymbol.precision)}</b>
                    </span>
                    {objLastPrediction.current.price > 0 && (
                      <span
                        className="lastPlacedOrderIcons"
                        id={`lastPlacedOrderType${
                          objLastPrediction.current.type === 1 ? "Up" : "Down"
                        }`}
                      >
                        {objLastPrediction.current.type === 1 ? (
                          <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}{" "}
                      </span>
                    )}
                  </div>
                  <div>
                    <Button
                      id="placeOrderDown"
                      className="orderIcons"
                      text="DOWN"
                      onClick={() => placeOrderOptions(2)}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </Button>
                  </div>
                </div>
              </Item>
              <Item>
                <Location screen="md lg" row={0} col={2} rowspan={2} />
                <div id="pred-amount-select">
                  <span>{currency.current} Amount</span>
                  <NumberBox
                    defaultValue={selAmount}
                    min={5}
                    max={maxNumberbox.current}
                    showSpinButtons={true}
                    step={selStepRange.current}
                    onValueChanged={handleValueAmountChange}
                  />
                </div>
                <div id="pred-payout-area">
                  <div>Potential Payout</div>
                  <h3>
                    <b>{amountPayout.toFixed(2)}</b>
                  </h3>
                  <hr style={{ width: "90%", marginBottom: "10px" }} />
                  <div>
                    <div>Payout</div>
                    <h2>
                      <b>{parseInt(payoutRate * 100)}%</b>
                    </h2>
                  </div>
                  <hr style={{ width: "90%", marginBottom: "10px" }} />
                  <div>
                    <div>Protected Payout</div>
                    <h3>
                      <b>5%</b>
                    </h3>
                  </div>
                </div>
              </Item>
              <Item>
                <Location screen="md lg sm xs" row={1} col={0} />
                <div
                  id={`progress-bar-users-choice${
                    percentagePredictionsUp === 0 &&
                    percentagePredictionsDown === 0
                      ? "-zero"
                      : ""
                  }`}
                  style={{ width: progressBarWidth }}
                >
                  <ProgressBar
                    width="100%"
                    min={0}
                    max={100}
                    statusFormat={statusFormat}
                    value={percentagePredictionsDown}
                  />
                  <div>
                    <p className="percentageLeft">
                      {percentagePredictionsDown}%
                    </p>
                    <p className="percentageCenter">Users Choice</p>
                    <p className="percentageRight">
                      {percentagePredictionsUp}%
                    </p>
                  </div>
                </div>
              </Item>
              <Item>
                <Location screen="sm xs" row={2} col={0} />
                <div
                  id="pred-amount-expiry-area-row"
                  style={{ display: "flex" }}
                >
                  <div style={{ flex: 1 }}>
                    <div id="pred-amount-select-row">
                      <span>{currency.current} Amount</span>
                      <NumberBox
                        defaultValue={selAmount}
                        min={5}
                        max={maxNumberbox.current}
                        showSpinButtons={true}
                        step={selStepRange.current}
                        onValueChanged={handleValueAmountChange}
                      />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div id="pred-expiry-select-row">
                      <span>Expiry</span>
                      <SelectBox
                        items={expiry}
                        defaultValue={selExpiry}
                        value={selExpiry}
                        onValueChanged={onValueSelectExpiryChanged}
                      />
                    </div>
                  </div>
                </div>
                <div id="pred-order-area-row" className="flex-container-start">
                  <div>
                    <Button
                      id="place-Order-Up-row"
                      className="order-icons-row"
                      text="UP"
                      onClick={() => placeOrderOptions(1)}
                    >
                      <FontAwesomeIcon icon={faCaretUp} />
                    </Button>
                  </div>
                  <div id="orderPrice-row">
                    {objLastPrediction.current.price > 0 && (
                      <span id={`lastPlacedOrderPrice-row`}>
                        {objLastPrediction.current.price.toFixed(
                          selSymbol.precision
                        )}
                      </span>
                    )}
                    <span id="placeOrderPrice-row">
                      <b>{renderPriceUpdate.toFixed(selSymbol.precision)}</b>
                    </span>
                    {objLastPrediction.current.price > 0 && (
                      <span
                        className="lastPlacedOrderIcons-row"
                        id={`lastPlacedOrderType${
                          objLastPrediction.current.type === 1 ? "Up" : "Down"
                        }-row`}
                      >
                        {objLastPrediction.current.type === 1 ? (
                          <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}
                      </span>
                    )}
                  </div>
                  <div>
                    <Button
                      id="place-Order-Down-row"
                      className="order-icons-row"
                      text="DOWN"
                      onClick={() => placeOrderOptions(2)}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </Button>
                  </div>
                </div>
                <div id="pred-payout-area-row">
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <div>Potential Payout</div>
                      <b>{amountPayout.toFixed(2)}</b>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div>Payout</div>
                      <b>{parseInt(payoutRate * 100)}%</b>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div>Protected Payout</div>
                      <b>5%</b>
                    </div>
                  </div>
                </div>
              </Item>
            </ResponsiveBox>
            <Toast
              visible={toastHint}
              message="Not enough funds!"
              type="error"
              displayTime={2000}
              closeOnClick={true}
              onHiding={() => {
                setToastHint(false);
              }}
            />
            <Toast
              visible={toastActivePredictions}
              message="Please end some pending positions (use TAKE or RISK). No more than 25 allowed to be open in parallel!"
              type="error"
              displayTime={3000}
              closeOnClick={true}
              onHiding={() => {
                setToastActivePredictions(false);
              }}
            />
            <Popup
              width={260}
              height={190}
              showTitle={true}
              title={"Cancel Order"}
              dragEnabled={false}
              showCloseButton={false}
              hideOnOutsideClick={false}
              visible={cancelMenuVisible}
              onHiding={() => setCancelMenuVisible(false)}
              contentRender={renderCancelOption}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default PredictionsCard;
