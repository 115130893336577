import React, { useRef, useState, useEffect, useCallback } from 'react';
import './Board.css';

import PredictionsTab from '../boardcards/PredictionsCard';
import PredictionsHistoryTab from '../boardcards/PredictionsHistoryCard';

const BoardLoadCard = (props) => {
  const { user, card_shortid, card_width, card_height, card_teamid } = props;
  const cardRef = useRef();

  const [observedCardHeight, setObservedCardHeight] = useState(100);
  const [observedCardWidth, setObservedCardWidth] = useState(100);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setObservedCardHeight(event[0].contentBoxSize[0].blockSize);
      setObservedCardWidth(event[0].contentBoxSize[0].inlineSize);
    });

    if (cardRef && cardRef.current) {
      resizeObserver.observe(cardRef.current);
    }

    return () => {
      if (resizeObserver && cardRef.current) {
        resizeObserver.unobserve(cardRef.current);
      }
    };
  }, [cardRef]);

  return (
    <div ref={cardRef}>
      {card_shortid && card_shortid === 'PredCard' && (
        <PredictionsTab
          user={user}
          card_width={card_width}
          card_height={card_height}
          card_teamid={card_teamid}
          cardWidth={observedCardWidth}
          cardHeight={observedCardHeight}
        />
      )}
      {card_shortid && card_shortid === 'PredHisCard' && (
        <PredictionsHistoryTab
          user={user}
          card_width={card_width}
          card_height={card_height}
          card_teamid={card_teamid}
          cardWidth={observedCardWidth}
          cardHeight={observedCardHeight}
        />
      )}
    </div>
  );
};

export default BoardLoadCard;
