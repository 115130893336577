import React, { useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';

import { useNavigate } from 'react-router-dom';
import { Template } from 'devextreme-react/core/template';
import { useAuth } from '../../contexts/auth';
import { io } from 'socket.io-client';

import './header.scss';

const Header = (props) => {
  const {
    menuToggleEnabled,
    toggleMenu,
  } = props;
  const { user } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    let socket = io.connect(process.env.REACT_APP_SOCKET_URL);

    socket.on('connect', () => {
      // console.log(socket);
      // console.log(user);
      // console.log(user.userId);

      socket.emit('user', user.userId);

      socket.emit('join', 'sys_events');
      socket.on('event', async (data) => {
        if ((data && user.userId === data.systemid) || user.portalid === data.systemid) {
          console.log(1, data);
        }
      });
    });
  }, [user]);

  return (
    <header className={'header-component'}>
      <React.Fragment>
        <Toolbar className={'header-toolbar'}>

          {/* <Item
            location={'before'}
            cssClass={'header-title'}
            text={user + ' '}
            onClick={() => {
              navigate('/board');
            }}
          /> */}

          <Item location={'after'} locateInMenu={'auto'} menuItemTemplate={'userPanelTemplate'}>
            <Button className={'user-button authorization'} width={210} height={'100%'} stylingMode={'text'}>
              <UserPanel menuMode={'context'} />
            </Button>
          </Item>
          <Template name={'userPanelTemplate'}>
            <UserPanel menuMode={'list'} />
          </Template>
        </Toolbar>
      </React.Fragment>
    </header>
  );
};

export default Header;
