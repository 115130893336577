import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  Sorting,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import HTTP from "../../../api/HTTP";
import moment from "moment";

import { usePrediction } from "../../../contexts/prediction";

const validateSymbol = (symbol) => {
  // console.log(symbol)
  // DB just contains USD and EUR pairs, not USDT or GEUR
  let validatedSymbol = "";
  if (symbol === "BTC/USD") {
    validatedSymbol = symbol.replace("BTC/USD", "BTC/USDT");
  } else if (symbol === "BTC/EUR") {
    validatedSymbol = symbol.replace("BTC/EUR", "BTC/GEUR");
  } else if (symbol === "ETH/USD") {
    validatedSymbol = symbol.replace("ETH/USD", "ETH/USDT");
  } else if (symbol === "ETH/EUR") {
    validatedSymbol = symbol.replace("ETH/EUR", "ETH/GEUR");
  } else if (symbol === "EUR/USD") {
    validatedSymbol = symbol.replace("EUR/USD", "GEUR/USDT");
  } else if (symbol === "GBP/USD") {
    validatedSymbol = symbol.replace("GBP/USD", "GGBP/USDT");
  } else if (symbol === "EUR/GBP") {
    validatedSymbol = symbol.replace("EUR/GBP", "GEUR/GGBP");
  } else {
    validatedSymbol = symbol;
  }

  return validatedSymbol;
};

const Closed = (props) => {
  const { user, cardWidth } = props;

  const { reloadPredictionHistory, assets } = usePrediction();

  const allowedPageSizes = [25, 50];

  const gridRef = useRef(null);

  const [closedPredictions, setClosedPredictions] = useState([]);

  // useEffect(() => console.log(7, cardWidth), [cardWidth]);

  useEffect(() => {
    const fetchClosedPredictions = async () => {
      try {
        const dataArray = await HTTP.get(`/predictions/closed/${user.userId}`);
        console.log(dataArray.data);
        setClosedPredictions(dataArray.data);
      } catch (err) {
        console.log("fetch-closed-predictions-error: ", err);
      }
    };

    if (user && user.userId) {
      fetchClosedPredictions();
    }
  }, [user, reloadPredictionHistory]);

  const validateSymbolName = (data) => {
    // console.log(data.value)
    // DB just contains USD and EUR pairs, not USDT or GEUR
    let validatedSymbol = "";
    if (data.value === "BTC/USD") {
      validatedSymbol = data.value.replace("BTC/USD", "BTC/USDT");
    } else if (data.value === "BTC/EUR") {
      validatedSymbol = data.value.replace("BTC/EUR", "BTC/GEUR");
    } else if (data.value === "ETH/USD") {
      validatedSymbol = data.value.replace("ETH/USD", "ETH/USDT");
    } else if (data.value === "ETH/EUR") {
      validatedSymbol = data.value.replace("ETH/EUR", "ETH/GEUR");
    } else if (data.value === "EUR/USD") {
      validatedSymbol = data.value.replace("EUR/USD", "GEUR/USDT");
    } else if (data.value === "GBP/USD") {
      validatedSymbol = data.value.replace("GBP/USD", "GGBP/USDT");
    } else if (data.value === "EUR/GBP") {
      validatedSymbol = data.value.replace("EUR/GBP", "GEUR/GGBP");
    } else {
      validatedSymbol = data.value;
    }

    return validatedSymbol;
  };

  const convertDate = (data) => {
    return moment(data.value).format("DD.MM.YYYY HH:mm:ss");
  };

  const validatePredictionType = (data) => {
    let type = "";
    if (data.value === 1) {
      type = <span className="orderUp">UP</span>;
    } else if (data.value === 2) {
      type = <span className="orderDown">DOWN</span>;
    } else {
      type = <span>INVALID TYPE</span>;
    }

    return type;
  };

  const getPayoutRate = (data) => {
    if (data.value !== null) {
      return (data.value * 100).toFixed(0) + " %";
    }
  };

  const renderPriceFormatEntered = (cell) => {
    // Get assets to render price precision
    let validatedSymbol = validateSymbol(cell.data.symbol);
    const getAsset = assets.find((el) => el.assetname === validatedSymbol);
    // console.log(10, getAsset.price_precision);
    const pricePrecision = getAsset.price_precision;
    return cell.data.price_entered.toFixed(pricePrecision);
  };

  const renderPriceFormatClose = (cell) => {
    // Get assets to render price precision
    let validatedSymbol = validateSymbol(cell.data.symbol);
    const getAsset = assets.find((el) => el.assetname === validatedSymbol);
    // console.log(10, getAsset.price_precision);
    const pricePrecision = getAsset.price_precision;
    return cell.data.price_close.toFixed(pricePrecision);
  };

  const setCurrency = (cell) => {
    if (cell.data.value !== null) {
      return cell.data.currency + " " + cell.data.amount;
    }
  };

  const calculatePayout = (cell) => {
    let type = "";
    let payout = cell.data.payout;
    if (cell.data.payout === cell.data.amount) {
      type = "payoutEqual";
    } else if (cell.data.payout === 0) {
      type = "payoutLoss";
    } else if (cell.data.payout > 0 && cell.data.payout <= cell.data.amount) {
      type = "payoutLoss";
      payout = cell.data.payout * cell.data.multiplier;
    } else if (cell.data.payout > cell.data.amount) {
      type = "payoutSuccess";
      payout = cell.data.payout * cell.data.multiplier;
    }

    return (
      <span className={type}>
        {cell.data.currency} {payout}
      </span>
    );
  };

  return (
    <React.Fragment>
      {closedPredictions && assets && (
        <DataGrid
          style={{
            height: 500,
          }}
          ref={gridRef}
          dataSource={closedPredictions}
          showBorders={true}
          remoteOperations={true}
        >
          <Sorting mode="none" />
          <Column
            dataField="symbol"
            dataType="string"
            width={100}
            cellRender={validateSymbolName}
          />
          <Column
            dataField="time_entered"
            caption="Time Entered"
            dataType="date"
            width={150}
            cellRender={convertDate}
          />
          <Column
            dataField="time_close"
            caption="Time Close"
            // visible={cardWidth < 600 ? false : true}
            dataType="date"
            width={150}
            cellRender={convertDate}
          />
          <Column dataField="expiry" dataType="string" width={60} />
          {/* <Column dataField="currency" dataType="string" /> */}
          <Column
            dataField="up_down"
            caption="Type"
            dataType="number"
            width={80}
            cellRender={validatePredictionType}
          />
          <Column
            dataField="payout_rate"
            caption="Rate"
            dataType="number"
            width={60}
            cellRender={getPayoutRate}
          />
          <Column
            dataField="price_entered"
            caption="Price Entered"
            dataType="number"
            width={110}
            cellRender={(cell) => renderPriceFormatEntered(cell)}
          />
          <Column
            dataField="price_close"
            caption="Price Close"
            dataType="number"
            width={110}
            cellRender={(cell) => renderPriceFormatClose(cell)}
          />
          <Column
            dataField="amount"
            dataType="number"
            width={130}
            cellRender={setCurrency}
          />
          <Column
            dataField="payout"
            dataType="number"
            width={130}
            cellRender={(cell) => calculatePayout(cell)}
          />
          <Paging defaultPageSize={allowedPageSizes[0]} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      )}
    </React.Fragment>
  );
};

export default Closed;
