import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import "./global.scss";

import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { PredictionProvider } from "./contexts/prediction";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";
import { locale } from "devextreme/localization";

import { usePrediction } from "./contexts/prediction";

function App() {
  const { user, loading } = useAuth();

  const { renderPriceUpdate, selSymbol } = usePrediction();

  useEffect(() => {
    document.title = renderPriceUpdate.toFixed(selSymbol.precision);
  }, [renderPriceUpdate, selSymbol]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content user={user} />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <PredictionProvider>
            <div className={`app ${screenSizeClass}`}>
              {locale("de")}
              <App />
            </div>
          </PredictionProvider>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
