import authService from "../services/AuthService";
import tokenService from "../services/TokenService";

export async function signIn(email, password, portalType) {
  try {
    const tokenResponse = await authService.login(email, password, portalType);

    console.log('-1-', tokenResponse);

    // if (tokenResponse.data.accessToken && tokenResponse.data.refreshToken) { // TODO: add refreshToken
    if (tokenResponse.data.accessToken) {
      // tokenService.setAccessAndRefreshToken(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);
      tokenService.setAccessToken(tokenResponse.data.accessToken);
      const currentUser = await authService.getAuthUser();

      console.log("-2-", currentUser);

      const tokenExpirationDate = new Date(
        new Date().getTime() + 1000 * 60 * 60 * 24
      );

      currentUser.userId = currentUser.id;
      currentUser.lastname = currentUser.lastName;
      currentUser.firstname = currentUser.firstName;
      currentUser.fullname = currentUser.lastName + " " + currentUser.firstName;
      currentUser.expiration = tokenExpirationDate.toISOString();

      return {
        isOk: true,
        user: currentUser,
      };
    }

    if (tokenResponse.data.hash) {
      return {
        isOk: true,
        auth2: tokenResponse.data,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export function logOut() {
  tokenService.removeAll();
}

export async function getUser() {
  // const noClientId = new UUID(0, 0)
  try {
    const currentUser = await authService.getAuthUser();

    currentUser.userId = currentUser.id;
    currentUser.lastname = currentUser.lastName;
    currentUser.firstname = currentUser.firstName;
    currentUser.fullname = currentUser.lastName + " " + currentUser.firstName;

    console.log(566, currentUser);

    return {
      isOk: true,
      data: currentUser,
      user: currentUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(
  recoveryCode,
  newPassword,
  confirmPassword
) {
  try {
    return authService.setNewPassword(
      recoveryCode,
      newPassword,
      confirmPassword
    );
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log("Reset password: " + email);
    const resetResponse = await authService.resetPasswordRequest(email);
    return {
      isOk: true,
      success: resetResponse.data.success,
    };
  } catch (e) {
    return {
      isOk: false,
      message: "Failed to reset password",
      error: e.response.data.fieldErrors.email,
    };
  }
}
