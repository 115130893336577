import { Routes, Route, Navigate } from "react-router-dom";
import { SingleCard } from "./layouts";
import { LoginForm, ChangePasswordForm, PasswordKeyForm } from "./components";

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <SingleCard title="Sign In">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path="/change-password/:recoveryCode"
        element={
          <SingleCard title="Change Password">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route
        path="/password-key"
        element={
          <SingleCard title="Password Key">
            <PasswordKeyForm />
          </SingleCard>
        }
      />
      <Route path="*" element={<Navigate to={"/login"} />}></Route>
    </Routes>
  );
}
