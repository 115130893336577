import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import {
  getUser,
  signIn as sendSignInRequest,
  logOut,
} from "../api/auth";

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.user);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password, portalType) => {
    const result = await sendSignInRequest(email, password, portalType);
    if (result.isOk && result.user !== undefined) {
      setUser(result.user);
    }

    return result;
  }, []);

  const signOut = useCallback(() => {
    setUser();
    logOut();
  }, []);

  const setCurrentUser = useCallback((currentUser) => {
    setUser(currentUser);
  }, []);

  const setUserServiceProviderContact = useCallback(
    (serviceProviderContact) => {
      setUser((prev) => ({
        ...prev,
        serviceProviderContact,
      }));
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        setCurrentUser,
        setUserServiceProviderContact,
        signIn,
        signOut,
        loading
      }}
      {...props}
    />
  );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
