import React from "react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import ActivePredictions from "../../components/predictions/history/Active";
import ClosedPredictions from "../../components/predictions/history/Closed";

import "./BoardCards.css";

const PredictionsHistoryCard = (props) => {
  const { user, card_height, cardWidth } = props;

  return (
    <React.Fragment>
      <div className="sd-header item">
        <div className="flex-container">
          <div className="dx-fieldset-header">Predictions History</div>
        </div>
        <div
          style={{
            height: `${card_height}vh - 120px`,
            width: cardWidth - 10,
            marginBottom: 10,
          }}
        >
          <TabPanel id="tabsHistory">
            <Item title="Active">
              <ActivePredictions user={user} cardWidth={cardWidth} />
            </Item>
            <Item title="Closed">
              <ClosedPredictions user={user} cardWidth={cardWidth} />
            </Item>
          </TabPanel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PredictionsHistoryCard;