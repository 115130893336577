import React, { useCallback, useEffect, useRef, useState } from "react";
import HTTP from "../../api/HTTP";
import { io } from "socket.io-client";
import { createChart, CrosshairMode } from "lightweight-charts";

import { usePrediction } from "../../contexts/prediction";

// const getTimeseriesData = async (symbol, resolution) => {
//   try {
//     const dataArray = await HTTP.get(`/chart/get-timeseries/${symbol}/${resolution}`);
//     console.log(dataArray.data);

//     return dataArray.data;
//   } catch (err) {
//     console.log("fetch-timeseries-: ", err);
//   }
// };

// const getPredictions = async (userid) => {
//   try {
//     const dataArray = await HTTP.get(`/predictions/get/${userid}`);
//     console.log(dataArray.data);

//     // Loop through all predictions to return active and closed
//     let obj = { active: [], closed: [] };

//     return obj;
//   } catch (err) {
//     console.log("fetch-predictions-: ", err);
//   }
// };

// const setMarkers = async (data) => {
//   const datesForMarkers = [data[data.length - 10], data[data.length - 9]];
//   console.log(datesForMarkers);
//   const indexOfMinPrice = 0;
//   for (var i = 1; i < datesForMarkers.length; i++) {
//     if (datesForMarkers[i].value < datesForMarkers[indexOfMinPrice].value) {
//       indexOfMinPrice = i;
//     }
//   }

//   const markers = [
//     {
//       time: data[data.length - 11].time,
//       position: "aboveBar",
//       color: "#000",
//       shape: "square",
//       text: "D",
//     },
//   ];
//   for (var i = 0; i < datesForMarkers.length; i++) {
//     if (i !== indexOfMinPrice) {
//       markers.push({
//         time: datesForMarkers[i].time,
//         position: "aboveBar",
//         color: "#da3434",
//         shape: "arrowDown",
//         text: "Down @ " + Math.floor(datesForMarkers[i].value + 2),
//       });
//     } else {
//       markers.push({
//         time: datesForMarkers[i].time,
//         position: "belowBar",
//         color: "#239e23",
//         shape: "arrowUp",
//         text: "Up @ " + Math.floor(datesForMarkers[i].value - 2),
//       });
//     }
//   }

//   return markers;
// };

const Lightweight = (props) => {
  const { userid, chartType, chartResolution, chartPeriod, chartWidthHeight } =
    props;

  const chart = useRef();
  const chartContainerRef = useRef();

  const [socket, setSocket] = useState(null);
  const [updatedProps, setUpdatedProps] = useState({ value: "" });

  const { setRenderPriceUpdate, selSymbol } = usePrediction();

  useEffect(() => {
    const fetchChartData = async () => {
      const symbol = selSymbol.symbol;
      // DB just contains USD and EUR pairs, not USDT or GEUR
      let validatedSymbol = "";
      if (symbol === "BTC/USDT") {
        validatedSymbol = symbol
          .replace("BTC/USDT", "BTC/USD")
          .replace("/", "%2F");
      } else if (symbol === "BTC/GEUR") {
        validatedSymbol = symbol
          .replace("BTC/GEUR", "BTC/EUR")
          .replace("/", "%2F");
      } else if (symbol === "ETH/USDT") {
        validatedSymbol = symbol
          .replace("ETH/USDT", "ETH/USD")
          .replace("/", "%2F");
      } else if (symbol === "ETH/GEUR") {
        validatedSymbol = symbol
          .replace("ETH/GEUR", "ETH/EUR")
          .replace("/", "%2F");
      } else if (symbol === "GEUR/USDT") {
        validatedSymbol = symbol
          .replace("GEUR/USDT", "EUR/USD")
          .replace("/", "%2F");
      } else if (symbol === "GGBP/USDT") {
        validatedSymbol = symbol
          .replace("GGBP/USDT", "GBP/USD")
          .replace("/", "%2F");
      } else if (symbol === "GEUR/GGBP") {
        validatedSymbol = symbol
          .replace("GEUR/GGBP", "EUR/GBP")
          .replace("/", "%2F");
      } else {
        validatedSymbol = symbol.replace("/", "%2F");
      }

      const response = await HTTP.get(
        `/chart/get-chart-data/${validatedSymbol}/${chartResolution}/${chartPeriod}`
      );
      const data = response.data;
      console.log(data);

      // Set current price
      setRenderPriceUpdate(data[data.length - 1].close);

      // Set min move for price precision
      const minMove = (pricePrecision) => {
        const zerosString = "0".repeat(pricePrecision - 1);
        return parseFloat("0." + zerosString + "1");
      };

      console.log(1111, minMove(selSymbol.precision));

      const seriesData = data.map((d) => {
        let hoursToAdd = 60 * 60 * 1000; // Add 1 hour to time from database

        // Without setting 1 hour for daily timeframes it will cause errors
        if (chartResolution === "1D" || chartPeriod === "1Y") {
          hoursToAdd = 60 * 60 * 1000; // Add 1 hour to time from database
        }
        return {
          time: (new Date(d.bucket).getTime() + hoursToAdd) / 1000,
          value: parseFloat(d.close),
        };
      });

      // console.log(seriesData);

      chart.current = createChart(chartContainerRef.current, {
        width: chartWidthHeight.width,
        height: chartWidthHeight.height,
        timeScale: {
          borderColor: "#485c7b",
          timeVisible: true,
          secondsVisible: true,
        },
      });

      if (chartType === "Area") {
        const areaSeries = chart.current.addAreaSeries({
          topColor: "rgba(0, 102, 255, 0.56)",
          bottomColor: "rgba(0, 102, 255, 0.2)",
          lineColor: "rgba(0, 102, 255, 1)",
          lineWidth: 2,
          priceFormat: {
            type: "price",
            precision: selSymbol.precision,
            minMove: minMove(selSymbol.precision),
            // minMove: 0.01,
          },
        });

        areaSeries.setData(seriesData);

        // Set markers
        //const markers = await setMarkers(seriesData)
        //areaSeries.setMarkers(markers);

        chart.current.timeScale().fitContent();

        // Room to connect
        let socketRoom = "";
        if (chartResolution === "1s") {
          socketRoom = "price_update_1_second";
        } else if (chartResolution === "1m") {
          socketRoom = "price_update_1_minute";
        } else if (chartResolution === "5m") {
          socketRoom = "price_update_5_minute";
        } else if (chartResolution === "15m") {
          socketRoom = "price_update_15_minute";
        } else if (chartResolution === "30m") {
          socketRoom = "price_update_30_minute";
        } else if (chartResolution === "1h") {
          socketRoom = "price_update_1_hour";
        } else if (chartResolution === "4h") {
          socketRoom = "price_update_4_hour";
        } else if (chartResolution === "1d") {
          socketRoom = "price_update_1_day";
        }

        if (chartPeriod === "1D") {
          socketRoom = "price_update_1_minute";
        } else if (chartPeriod === "5D") {
          socketRoom = "price_update_5_minute";
        } else if (chartPeriod === "1M") {
          socketRoom = "price_update_30_minute";
        } else if (chartPeriod === "3M") {
          socketRoom = "price_update_1_hour";
        } else if (chartPeriod === "6M") {
          socketRoom = "price_update_4_hour";
        } else if (chartPeriod === "1Y") {
          socketRoom = "price_update_1_day";
        }

        console.log(socketRoom);

        const newSocket = io.connect(
          process.env.REACT_APP_TIMESCALE_ADAPTER_URL
        );
        setSocket(newSocket);
        newSocket.emit("join", socketRoom);
        newSocket.on(validatedSymbol.replace("%2F", "/"), (pl) => {
          // console.log(pl);
          console.log(pl.value);
          pl.time = pl.time / 1000;
          setRenderPriceUpdate(pl.value);

          areaSeries.update(pl);
        });
      } else if (chartType === "Line") {
        // const lineSeries = chart.current.addLineSeries({
        //   color: "#2962FF",
        //   priceFormat: {
        //     type: "price",
        //     precision: pricePrecision,
        //     minMove: 0.00001,
        //   },
        // });
        // lineSeries.setData(seriesData);
        // chart.current.timeScale().fitContent();
        // // const socket = io.connect(process.env.REACT_APP_TIMESCALE_ADAPTER_URL);
        // socketRef.current.emit("join", "price_update");
        // socketRef.current.on(validatedSymbol.replace("%2F", "/"), (pl) => {
        //   console.log(pl);
        //   // console.log(new Date(pl.time).toISOString());
        //   pl.time = pl.time / 1000;
        //   setRenderPriceUpdate(pl.value);
        //   lineSeries.update(pl);
        // });
      } else if (chartType === "Candles") {
        // const seriesData = data.map((d) => {
        //   return {
        //     time: (new Date(d.bucket).getTime() + 2 * 60 * 60 * 1000) / 1000,
        //     open: parseFloat(d.open),
        //     high: parseFloat(d.high),
        //     low: parseFloat(d.low),
        //     close: parseFloat(d.close),
        //   };
        // });
        // // console.log(seriesData);
        // const candleSeries = chart.current.addCandlestickSeries({
        //   priceFormat: {
        //     type: "price",
        //     precision: pricePrecision,
        //     minMove: 0.00001,
        //   },
        //   //   upColor: "#4bffb5",
        //   //   downColor: "#ff4976",
        //   //   borderDownColor: "#ff4976",
        //   //   borderUpColor: "#4bffb5",
        //   //   wickDownColor: "#838ca1",
        //   //   wickUpColor: "#838ca1",
        // });
        // candleSeries.setData(seriesData);
        // const socket = io.connect(process.env.REACT_APP_TIMESCALE_ADAPTER_URL);
        // socket.emit("join", "price_update");
        // socket.on(validatedSymbol.replace("%2F", "/"), (pl) => {
        //   console.log(pl);
        //   pl.time = pl.time / 1000;
        //   candleSeries.update(pl);
        // });
      }

      // const volumeSeries = chart.current.addHistogramSeries({
      //   color: "#182233",
      //   lineWidth: 2,
      //   priceFormat: {
      //     type: "volume",
      //   },
      //   overlay: true,
      //   scaleMargins: {
      //     top: 0.8,
      //     bottom: 0,
      //   },
      // });
      // volumeSeries.setData(volumeData);
    };

    // console.log(999, selSymbol);
    fetchChartData();
    setUpdatedProps({
      value: `${selSymbol.symbol} ${chartType} ${chartResolution} ${chartPeriod}`,
    });
  }, [selSymbol.symbol, chartType, chartResolution, chartPeriod]);

  useEffect(() => {
    // console.log(chartWidthHeight);

    if (chart.current && chartWidthHeight) {
      chart.current.resize(chartWidthHeight.width, chartWidthHeight.height);
    }
  }, [chartWidthHeight]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
        console.log("Disconnected chart render");
      }
    };
  }, [socket]);

  return (
    <>
      <div ref={chartContainerRef} style={{ color: "transparent" }}>
        {updatedProps.value}
      </div>
    </>
  );
};

export default Lightweight;
