import React, { useState, useEffect, useRef } from "react";

import "./Board.css";

import HTTP from "../../api/HTTP";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import BoardLoadCard from "./BoardViewLoadCard";

function windowHeight() {
  return window.innerHeight < 600 ? "sm" : "lg";
}

function renderCardHeight(cardheight) {
  const width = window.innerWidth;
  const height = window.innerHeight;
  // console.log(width, height);

  let cardHeight = {};
  if (height < 600) {
    cardHeight = {
      height: `calc(${cardheight}vh + 440px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 600 && height < 700) {
    cardHeight = {
      height: cardheight + "vh",
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 700 && height < 800) {
    cardHeight = {
      height: `calc(${cardheight}vh - 80px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 800 && height < 900) {
    cardHeight = {
      height: `calc(${cardheight}vh - 120px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 900 && height < 1100) {
    cardHeight = {
      height: `calc(${cardheight}vh - 200px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 1100 && height < 1200) {
    cardHeight = {
      height: `calc(${cardheight}vh - 350px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 1200 && height < 1300) {
    cardHeight = {
      height: `calc(${cardheight}vh - 420px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  } else if (height >= 1300) {
    cardHeight = {
      height: `calc(${cardheight}vh - 480px)`,
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
    };
  }

  return cardHeight;
}

const BoardLoadPage = () => {
  const { user } = useAuth();

  const [cardRows, setCardRows] = useState([]);
  const [cardLayout, setCardLayout] = useState([]);

  useEffect(() => {
    const fetchRows = async () => {
      try {
        const dataArray = await HTTP.get(
          `/board/board-card-rows/${user.userId}`
        );
        setCardRows(dataArray.data);
      } catch (err) {
        console.log("fetchCardLayout Error: ", err);
      }
    };
    const fetchCardLayout = async () => {
      try {
        const dataArray = await HTTP.get(`/board/board-card/${user.userId}`);
        setCardLayout(dataArray.data);
      } catch (err) {
        console.log("fetchCardLayout Error: ", err);
      }
    };

    if (user && user.userId) {
      fetchRows();
      fetchCardLayout();
    }
  }, [user, user.userId]);

  const rowCards = (anRow) => {
    const result = cardLayout.filter((obj) => {
      return obj.board_rowid === anRow;
    });
    return result;
  };

  return (
    <React.Fragment>
      <div>
        {cardRows.map((card_item, index) => {
          const oneLine = rowCards(card_item.board_rowid);
          return (
            <div className={"flex-container-board"} key={index}>
              {oneLine.map((colItem, index) => {
                return (
                  <div
                    className={"content-block"}
                    key={index}
                    style={{
                      flexBasis: 300,
                      flex: colItem.cardstyle,
                      maxWidth: 1150,
                    }}
                  >
                    <div className={"dx-card"}>
                      <div
                        style={renderCardHeight(colItem.cardheight)}
                        //   windowHeight() === "lg"
                        //     ? {
                        //         height: colItem.cardheight + "vh",
                        //         marginRight: 20,
                        //         marginLeft: 20,
                        //         marginTop: 10,
                        //       }
                        //     : {
                        //         height: `calc(${colItem.cardheight}vh + 440px)`,
                        //         marginRight: 5,
                        //         marginLeft: 5,
                        //         marginTop: 5,
                        //       }
                        // }
                      >
                        <BoardLoadCard
                          user={user}
                          card_shortid={colItem.card_shortid}
                          card_width={colItem.cardstyle}
                          card_height={colItem.cardheight}
                          card_teamid={colItem.card_teamid}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default BoardLoadPage;
