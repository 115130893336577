import React, { useState, useEffect, useCallback } from 'react';
import './Board.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusLarge as faDuoPlusLarge,
  faMinus as faDuoMinus,
  faArrowRight as faDuoArrowRight,
  faArrowDown as faDuoArrowDown,
} from '@fortawesome/pro-duotone-svg-icons';

import ArrayStore from 'devextreme/data/array_store';

import { SelectBox } from 'devextreme-react/select-box';

import Button from 'devextreme-react/button';
import { NumberBox } from 'devextreme-react/number-box';

import HTTP from '../../api/HTTP';

const BoardViewDefCard = (props) => {
  const { user, selCard, rowCardCount, colCardCount, doCardPageReload, defType } = props;
  const [userBoardCardsData, setUserBoardCardsData] = useState([]);
  const [selCardid, setSelCardid] = useState(selCard.cardid);
  const [doReload, setDoReload] = useState(false);
  const [selCardWidth, setSelCardWidth] = useState(selCard.cardstyle);
  const [selCardHeight, setSelCardHeight] = useState(selCard.cardheight);
  const [newColId, setNewColId] = useState(colCardCount + 1);
  const [newRowId, setNewRowId] = useState(rowCardCount + 1);
  const [addNewCardOnRow, setAddNewCardOnRow] = useState(false);

  useEffect(() => {
    setSelCardid(selCard.cardid);
    setSelCardWidth(selCard.cardstyle);
    setSelCardHeight(selCard.cardheight);
    setNewColId(colCardCount + 1);
    setNewRowId(rowCardCount + 1);

    // console.log(0, selCard);
  }, [selCard]);

  useEffect(() => {
    const fetchUserBoardCards = async () => {
      try {
        const dataArray = await HTTP.get(`/board/sys-board-cards`);
        // console.log(dataArray.data)
        setUserBoardCardsData(dataArray.data);
      } catch (err) {
        console.log('fetchUserBoardCards Error: ', err);
      }
    };
    fetchUserBoardCards();
  }, []);

  const stUserBoardCardsData = new ArrayStore({
    key: 'cardid',
    data: userBoardCardsData,
  });

  const saveCardtoBoard = async (anOperation) => {
    // console.log(4567, assignedData);
    try {
      await HTTP.post(
        '/board/mod-board-card',
        JSON.stringify({
          operation: anOperation,
          board_card_entryid: anOperation === 1 || anOperation === 5 ? -5 : selCard.board_card_entryid,
          userid: user.userId,
          board_rowid: anOperation === 5 ? newRowId : selCard.board_rowid,
          board_colid: anOperation === 1 ? newColId : selCard.board_colid,
          cardid: selCardid,
          cardstyle: selCardWidth,
          cardheight: selCardHeight,
        })
      );
      setDoReload(!doReload);
      doCardPageReload(true);
    } catch (err) {
      console.log(err);
    }
  };

  const saveCardId = useCallback((e) => {
    setSelCardid(e.value);
  }, []);

  const saveCardWidth = useCallback((e) => {
    setSelCardWidth(e.value);
  }, []);

  const saveCardHeight = useCallback((e) => {
    setSelCardHeight(e.value);
  }, []);

  const addCardonRow = async () => {
    await saveCardtoBoard(1);
  };

  const removeCardfromRow = async () => {
    if (colCardCount > 0) {
      setAddNewCardOnRow(false);
      await saveCardtoBoard(3);
    }
  };

  const removeCardfromCol = async () => {
    if (rowCardCount > 0) {
      setAddNewCardOnRow(false);
      await saveCardtoBoard(3);
    }
  };

  const addCardNewRow = async () => {
    await saveCardtoBoard(5);
  };

  const editCardContent = async () => {
    await saveCardtoBoard(2);
  };

  return (
    <div className='sd-header item'>
      <div className='flex-container'>
        <div className='dx-fieldset-header'>Card Content</div>

        {selCard.board_colid === colCardCount && (
          <div id='sec-details-line'>
            <div style={{ flexDirection: 'row' }}>
              <FontAwesomeIcon
                id='board-def-btn-1'
                width={36}
                icon={faDuoMinus}
                color='red'
                size='xl'
                cursor='pointer'
                onClick={removeCardfromRow}
              />

              <FontAwesomeIcon
                width={36}
                icon={faDuoPlusLarge}
                color='blue'
                size='xl'
                cursor='pointer'
                onClick={addCardonRow}
              />

              <FontAwesomeIcon width={36} icon={faDuoArrowRight} color='black' size='xl' />
            </div>
          </div>
        )}
      </div>

      <div className='center-area'>
        <div className='center-child'>
          <SelectBox
            label='Card'
            height={41}
            dataSource={stUserBoardCardsData}
            valueExpr='cardid'
            displayExpr='card_name'
            value={selCardid}
            onValueChanged={saveCardId}
          ></SelectBox>
          <div className='dx-field' id='board-def-number-box'>
            <div className='dx-field-label'>Width</div>
            <div className='dx-field-value'>
              <NumberBox
                min={1}
                max={100}
                defaultValue={selCard.cardstyle}
                value={selCardWidth}
                showSpinButtons={true}
                onValueChanged={saveCardWidth}
              />
            </div>
          </div>
          <div className='dx-field' id='board-def-number-box'>
            <div className='dx-field-label'>Heigth</div>
            <div className='dx-field-value'>
              <NumberBox
                defaultValue={selCard.cardheight}
                min={1}
                max={100}
                value={selCardHeight}
                showSpinButtons={true}
                onValueChanged={saveCardHeight}
              />
            </div>
          </div>

          <Button id='def-card-btn' text='Save' icon='save' onClick={editCardContent} />
        </div>
      </div>
      {selCard.board_rowid === rowCardCount && (
        <div id='sec-details-line'>
          <div style={{ flexDirection: 'row' }}>
            <FontAwesomeIcon width={36} icon={faDuoArrowDown} color='black' size='xl' />

            <FontAwesomeIcon
              width={36}
              icon={faDuoPlusLarge}
              color='blue'
              size='xl'
              cursor='pointer'
              onClick={addCardNewRow}
            />

            <FontAwesomeIcon
              id='board-def-btn-1'
              width={36}
              icon={faDuoMinus}
              color='red'
              size='xl'
              cursor='pointer'
              onClick={removeCardfromCol}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BoardViewDefCard;
